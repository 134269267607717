import React from 'react'

import Layout from '@fullstakk/fms-frontend-theme'

import Seo from '../lib/components/Application/Seo'
import Service from '../lib/utility/Service'

/**
 * Encapsulates the 404 page of the frontend.
 */
export default class Error404 extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        const App = Service.instance('App')
        const authUser = Service.instance('Auth').getUser()

        return (
            <Layout
                theme='HeaderMainFooter'
                title={App.getName()}
                home={{link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'}}
                user={authUser}
                onLogOut={() => App.signOut()}
                mainClassName='wrapper-flex'
            >
                <Seo title='404 error' description='The requested page does not exist!' />
                <h1>404 error</h1>
                <p>The requested page does not exist!</p>
            </Layout>
        )
    }
}
